var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({ height: '100%', width: '100%' })},[(!_vm.player)?_c('div',{staticClass:"d-flex justify-center align-center",style:({
      width: '100%',
      height: '100%',
    })},[_c('v-icon',[_vm._v("mdi-account-question")]),_c('div',{staticClass:"pl-1"},[_vm._v(" No Player Available ")])],1):_c('div',{staticClass:"d-flex flex-column",style:({
      width: '100%',
      height: '100%',
      'background-color': 'rgba(0, 0, 0, 0.3)',
    })},[(_vm.player.streamSource)?_c('twitch-player',{attrs:{"channel":_vm.player.streamSource,"unmuted":_vm.selected || _vm.unmuted}}):_c('div',{staticClass:"d-flex justify-center align-center",style:({
        width: '100%',
        height: '100%',
      })},[_c('v-icon',[_vm._v("mdi-twitch")]),_c('div',{staticClass:"pl-1"},[_vm._v(" No Stream Set ")])],1),_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('div',{class:{ 'pa-2': _vm.selected, 'pa-1': !_vm.selected, 'd-flex': true, 'flex-grow-1': true },style:({ 'font-size': _vm.selected ? '2em' : undefined })},[(_vm.selected || !_vm.canSelect)?_c('div',{staticClass:"d-flex justify-center",style:({ width: '100%' })},[_vm._v(" "+_vm._s(_vm.name)+" ")]):_c('tooltip',{style:({ width: '100%' }),attrs:{"top":""},scopedSlots:_vm._u([{key:"elem",fn:function(){return [_c('div',{staticClass:"d-flex justify-center SelectBtn",on:{"click":function($event){return _vm.setSelected()}}},[_vm._v(" "+_vm._s(_vm.name)+" ")])]},proxy:true},{key:"tooltip",fn:function(){return [_vm._v(" Set Selected ")]},proxy:true}])})],1),_c('v-switch',{class:{ 'mt-0': true, 'mr-2': _vm.selected || _vm.showLabel },style:({ 'padding-top': !_vm.selected ? '1px' : undefined }),attrs:{"input-value":_vm.active,"loading":_vm.activeChanging,"inset":"","dense":!_vm.selected,"hide-details":!_vm.selected,"persistent-hint":"","hint":"Active?","label":_vm.showLabel ? 'Active?' : undefined},on:{"change":_vm.changeActive}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }