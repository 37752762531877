























































































import { Vue, Component } from 'vue-property-decorator';
import clone from 'clone';
import TwitchPlayerPanel from '@/components/Dashboard/TwitchPlayerPanel.vue';
import { API } from '@/types';
import { rootModule } from '@/store';
import { storeModule, storeNS } from '../store';

@Component({
  components: {
    TwitchPlayerPanel,
  },
})
export default class extends Vue {
  @storeNS.State readonly players!: API.Players.GET[];
  selectedPlayers: number[] = [];
  dialog = true;

  get selectedEvent(): number | null { return rootModule.selectedEvent; }

  playerModified(player: API.Players.GET): void {
    storeModule.updateStoredPlayer(player);
  }

  get playersSorted(): API.Players.GET[] {
    return clone(this.players)
      .sort((a, b) => {
        const aName = a.user?.name || '?';
        const bName = b.user?.name || '?';
        return aName.localeCompare(bName, 'en', { sensitivity: 'base' });
      });
  }

  get playersFiltered(): API.Players.GET[] {
    return this.playersSorted.filter((p) => this.selectedPlayers.includes(p.id));
  }
}
