






















































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { API } from '@/types';
import Tooltip from '@/components/Dashboard/Tooltip.vue';
import TwitchPlayer from './TwitchPlayer.vue';
import { apiModify } from '@/api';

@Component({
  components: {
    Tooltip,
    TwitchPlayer,
  },
})
export default class extends Vue {
  @Prop(Object) readonly player!: API.Players.GET | undefined;
  @Prop(Boolean) readonly selected!: boolean;
  @Prop(Boolean) readonly unmuted!: boolean;
  @Prop(Boolean) readonly canSelect!: boolean;
  @Prop(Boolean) readonly showLabel!: boolean;
  active = this.player?.active || false;
  activeChanging = false;

  @Watch('player.active')
  onPlayerActiveChange(val: boolean): void {
    this.active = val;
  }

  get name(): string {
    return this.player?.user?.name || '?';
  }

  async setSelected(): Promise<void> {
    if (this.player) {
      const { data } = await apiModify('playerOrder', this.player.eventId, {
        playerId: this.player.id, index: 0,
      });
      this.$emit('playerOrderModified', data);
    }
  }

  async changeActive(state: boolean): Promise<void> {
    if (this.player) {
      try {
        this.activeChanging = true;
        const { data } = await apiModify('players', this.player.id, { active: state });
        this.$emit('playerModified', data);
      } catch (err) {
        this.active = this.player.active;
      }
      this.activeChanging = false;
    }
  }
}
