import { render, staticRenderFns } from "./TwitchPlayerPanel.vue?vue&type=template&id=09ff5aa4&scoped=true&"
import script from "./TwitchPlayerPanel.vue?vue&type=script&lang=ts&"
export * from "./TwitchPlayerPanel.vue?vue&type=script&lang=ts&"
import style0 from "./TwitchPlayerPanel.vue?vue&type=style&index=0&lang=css&"
import style1 from "./TwitchPlayerPanel.vue?vue&type=style&index=1&id=09ff5aa4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09ff5aa4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VIcon,VSwitch})
